import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [3,4];

export const dictionary = {
		"/(public)": [5,[2]],
		"/(webapp)/admin": [~10,[3]],
		"/(webapp)/admin/emails": [~11,[3]],
		"/(webapp)/admin/menus": [~13,[3]],
		"/(webapp)/admin/menu/[menuFileId]": [~12,[3]],
		"/(webapp)/admin/restaurants": [~14,[3]],
		"/login": [~24],
		"/login/impersonate/[userId]": [~25],
		"/logout": [~26],
		"/logout/stopImpersonation": [~27],
		"/(webapp)/organization": [~15,[3]],
		"/(webapp)/organization/[orgId]": [~16,[3]],
		"/(webapp)/organization/[orgId]/restaurants": [~17,[3]],
		"/(webapp)/organization/[orgId]/settings/billing": [~18,[3]],
		"/(webapp)/organization/[orgId]/settings/billing/reactivate": [~19,[3]],
		"/(public)/privacy-policy": [6,[2]],
		"/(webapp)/restaurant/[slug]/(dashboard)": [~20,[3,4]],
		"/(webapp)/restaurant/[slug]/info": [~21,[3,4]],
		"/(webapp)/restaurant/[slug]/reservations": [22,[3,4]],
		"/(webapp)/settings": [23,[3]],
		"/(public)/terms": [7,[2]],
		"/(public)/terms/restaurants": [8,[2]],
		"/(public)/terms/website": [9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';